/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { APIGetBatteryPolicies } from '../../../config/restAPI/FetchAPI';
import Title from '../../../component/title/Title';
import Table from '../../../component/table/Table';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import SnackBar from '../../../component/snackbar/Snackbar';
import { CreateWithIcon } from '../../../component/buttonComponent/ButtonComponent';
import InventoryTableEmptyState from '../../../component/tableEmptyState/InventoryTableEmptyState';
import TruckIcon from '../../../image/truckIcon.svg';
import { useApiData } from '../../../config/reducer/Modules';
import './BatteryConfigurator.css';
import '../geofence/geofence.css';

const seperator = [
    <img loading="lazy" src={TruckIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Battery Configurator</div>,
];

const BatteryConfigurator = (props) => {
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const { apiResponse } = useApiData();
    const navigate = useNavigate();
    const location = useLocation();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [devicesState, setDevicesState] = useState(true);
    const [emptyState, setEmptyState] = useState(false);
    const [showTable, setShowTable] = useState(true);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const bulkActionDisabled = devicesState === false || selectedRowCount <= 1;
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const role = Cookies.get('role');
    const [deviceData, setDeviceData] = useState([]);
    const [adminDeviceData, setAdminDeviceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [adminTotalRows, setAdminTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [adminConsole, setAdminConsole] = useState(false);
    const [triggerSnackbar, settriggerSnackbar] = useState(false);
    const [textTitleSnackbar, setTextTitleSnackbar] = useState(null);
    const [textSubSnackbar, setTextSubSnackbar] = useState(null);
    const [moduleSnackbar, setModuleSnackbar] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [searchText, setSearchText] = useState('');
    const defaultParams = { pageNumber: 1 };

    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();

    const inventoryNavigationList = [
        {
            text: 'Assets & Devices', value: 1, path: '/inventory/assetdevices', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.role === 'System Administrator') ? true : apiResponse?.module?.fleetManagement?.asset,
        },
        {
            text: 'Connectivity', value: 4, path: '/inventory/connectivity', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.connectivity,
        },
        {
            text: 'Battery Configurator', value: 5, path: '/inventory/batteryconfigurator', enabled: apiResponse?.role === 'System Administrator',
        },
    ];

    const adminFetchBatteryConfigs = async (params) => {
        try {
            setLoading(true);
            const result = await APIGetBatteryPolicies(setLoading, token, agencyID, params);
            if (result.status === 200) {
                const { data, totalData } = result.data;
                if (data.length > 0) {
                    const reducer = data.map((x) => ({
                        id: x.oid,
                        name: x.name,
                        batteryVoltage: x.batteryVoltage,
                        batteryCapacity: x.batteryCapacity,
                        description: x.description,
                        tableMap: x.tableMap,
                        equationMap: x.equationMap,
                        percentageMapping: x.percentageMapping,
                        dateCreated: {
                            date: moment(x.createdAt).format('DD MMM YYYY'),
                            time: moment(x.createdAt).format('hh:mm:ssa [GMT+8]'),
                        },
                        totalAssets: x.associatedAsset.length,
                    }));
                    setShowTable(true);
                    setEmptyState(false);
                    setAdminDeviceData(reducer);
                    setAdminTotalRows(totalData);
                    setDevicesState(true);
                    setLoading(false);
                } else {
                    setShowTable(false);
                    setEmptyState(true);
                    setLoading(false);
                }
            } else if (result.status === 204) {
                setShowTable(false);
                setEmptyState(true);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setDevicesState(false);
            console.error('Error fetching battery policies:', err);
        }
    };

    const handleAdminPageChange = (page) => {
        const rowsParams = {
            pageNumber: page,
        };
        adminFetchBatteryConfigs(rowsParams);
    };

    const handleAddBatteryConfigButton = () => {
        navigate('/inventory/batteryConfigurator/addBatteryConfig', { replace: true });
    };

    const switchLoadData = (value) => {
        switch (value) {
            case 'System Administrator':
                setAdminConsole(true);
                adminFetchBatteryConfigs(defaultParams);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (location.state) {
            if (location.state.snackbarStatus) {
                settriggerSnackbar(true);
                setTextTitleSnackbar(location.state.snackbarTitle);
                setTextSubSnackbar(location.state.snackbarSub);
                setModuleSnackbar(location.state.snackbarStatus);
                navigate(location.pathname, { replace: true });
            }
        }
     }, [location.state]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        setSearchTimeout(
            setTimeout(() => {
                const searchingValue = {
                    searchKey: searchText,
                    pageNumber: 1,
                };
                adminFetchBatteryConfigs(searchingValue);
            }, 200),
        );
        return () => clearTimeout(searchTimeout);
    }, [searchText]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        if (token) {
            switchLoadData(role);
        }
        setNavigtionClick(5);
        setNavigationState('inventory');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className="inventory-container">
            <ListNavigationTab list={inventoryNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="inventory-header">
                <BreadCrumbs customSeperator={seperator} />
                <Title title="Battery Configurator" subtitle="This module is for configuring battery policy parameters that can be applied to different asset types" titleSize="1.875rem" />
            </div>
            <div className="inventory-body">
                <div className="inventory-body-filter">
                    <div className="inventory-filter-button">
                        <CreateWithIcon label="Create Policy" onClickFunction={handleAddBatteryConfigButton} buttonWidth="9.75rem" buttonHeight="2.75rem" module="policy" />
                    </div>
                </div>
                {showTable && (
                    <div className={loading ? 'inventory-body-text-loading' : 'inventory-body-text'}>
                        <Table
                          selectedRowCount={selectedRowCount}
                          setSelectedRowCount={setSelectedRowCount}
                          tableData={adminDeviceData}
                          pagination
                          module="batteryConfigurator"
                          handlePageChange={handleAdminPageChange}
                          loading={loading}
                          totalRows={adminTotalRows}
                          backgroundColor="#F9FAFB"
                        />
                    </div>
                )}
                {emptyState && (
                    <div className="inventory-body-empty">
                        <InventoryTableEmptyState />
                    </div>
                )}
            </div>
            {
                triggerSnackbar && (
                <div className="geofence-snackbar-container">
                    <SnackBar state={triggerSnackbar} setState={settriggerSnackbar} title={textTitleSnackbar} subtitile={textSubSnackbar} module={moduleSnackbar} action={moduleSnackbar} />
                </div>
                )
            }
        </div>
    );
};

export default BatteryConfigurator;
