import React, { useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import { BackButton, PrimaryButton, SecondaryButton } from '../../../../component/buttonComponent/ButtonComponent';
import Autocomplete from '../../../../component/autocomplete/Autocomplete';
import ColoredLine from '../../../../component/coloredLine/ColoredLine';
import Title from '../../../../component/title/Title';
import TextInput from '../../../../component/textInput/TextInput';
import { APICreateDevice } from '../../../../config/restAPI/PostAPI';
import { APIGetAgencies, APIGetBatteryPolicies } from '../../../../config/restAPI/FetchAPI';
import SnackBar from '../../../../component/snackbar/Snackbar';
import DropDownList from '../../../../component/iconDropDown/iconDropDown';
import TextArea from '../../../../component/textAreaLimit/TextArea';
import ClearableDropdown from '../../../../component/clearableDropdown/clearableDropdown';
import './addDevice.css';

const assetTypeOptions = [
    {
        value: 'Rail',
        label: 'Rail',
        icon:
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 22H14.5M8 2H16M12 5V2M4 12H20M17 19L18.5 22M7 19L5.5 22M8.5 15.5H8.51M15.5 15.5H15.51M8.8 19H15.2C16.8802 19 17.7202 19 18.362 18.673C18.9265 18.3854 19.3854 17.9265 19.673 17.362C20 16.7202 20 15.8802 20 14.2V9.8C20 8.11984 20 7.27976 19.673 6.63803C19.3854 6.07354 18.9265 5.6146 18.362 5.32698C17.7202 5 16.8802 5 15.2 5H8.8C7.11984 5 6.27976 5 5.63803 5.32698C5.07354 5.6146 4.6146 6.07354 4.32698 6.63803C4 7.27976 4 8.11984 4 9.8V14.2C4 15.8802 4 16.7202 4.32698 17.362C4.6146 17.9265 5.07354 18.3854 5.63803 18.673C6.27976 19 7.11984 19 8.8 19ZM9 15.5C9 15.7761 8.77614 16 8.5 16C8.22386 16 8 15.7761 8 15.5C8 15.2239 8.22386 15 8.5 15C8.77614 15 9 15.2239 9 15.5ZM16 15.5C16 15.7761 15.7761 16 15.5 16C15.2239 16 15 15.7761 15 15.5C15 15.2239 15.2239 15 15.5 15C15.7761 15 16 15.2239 16 15.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>,
        subtitle: 'Categorises all rail and track based transport',
    },
    {
        value: 'Road',
        label: 'Road',
        icon:
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 7H16.3373C16.5818 7 16.7041 7 16.8192 7.02763C16.9213 7.05213 17.0188 7.09253 17.1083 7.14736C17.2092 7.2092 17.2957 7.29568 17.4686 7.46863L21.5314 11.5314C21.7043 11.7043 21.7908 11.7908 21.8526 11.8917C21.9075 11.9812 21.9479 12.0787 21.9724 12.1808C22 12.2959 22 12.4182 22 12.6627V15.5C22 15.9659 22 16.1989 21.9239 16.3827C21.8224 16.6277 21.6277 16.8224 21.3827 16.9239C21.1989 17 20.9659 17 20.5 17M15.5 17H14M14 17V7.2C14 6.0799 14 5.51984 13.782 5.09202C13.5903 4.71569 13.2843 4.40973 12.908 4.21799C12.4802 4 11.9201 4 10.8 4H5.2C4.0799 4 3.51984 4 3.09202 4.21799C2.71569 4.40973 2.40973 4.71569 2.21799 5.09202C2 5.51984 2 6.0799 2 7.2V15C2 16.1046 2.89543 17 4 17M14 17H10M10 17C10 18.6569 8.65685 20 7 20C5.34315 20 4 18.6569 4 17M10 17C10 15.3431 8.65685 14 7 14C5.34315 14 4 15.3431 4 17M20.5 17.5C20.5 18.8807 19.3807 20 18 20C16.6193 20 15.5 18.8807 15.5 17.5C15.5 16.1193 16.6193 15 18 15C19.3807 15 20.5 16.1193 20.5 17.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>,
        subtitle: 'Categorises all road and land based transport',
    },
];

export const fuelTypeOptions = [
    {
        value: 'obd',
        label: 'OBD',
        subtitle: 'On-Board Diagnostics',
    },
    {
        value: 'can',
        label: 'CAN',
        subtitle: 'Controller Area Network (CAN) bus',
    },
    {
        value: 'fms',
        label: 'FMS',
        subtitle: 'Fuel Management System',
    },
    {
        value: 'externalSensor',
        label: 'External Sensor',
        subtitle: 'External Sensors',
    },
    {
        value: 'dashboard',
        label: 'Dashboard',
        subtitle: 'Others',
    },

];

export const mileageTypeOptions = [
    {
        value: 'obd',
        label: 'OBD',
        subtitle: 'On-Board Diagnostics',
    },
    {
        value: 'can',
        label: 'CAN',
        subtitle: 'Controller Area Network (CAN) bus',
    },
    {
        value: 'gnss',
        label: 'GNSS',
        subtitle: 'Global Navigation Satelite System',
    },
    {
        value: 'tachograph',
        label: 'Tachograph',
        subtitle: 'Others',
    },
    {
        value: 'externalSensor',
        label: 'External Sensor',
        subtitle: 'External Sensors',
    },
    {
        value: 'dashboard',
        label: 'Dashboard',
        subtitle: 'Others',
    },
];

const AddDevice = () => {
    const navigate = useNavigate();
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agencyUUID');
    const [loading, setLoading] = useState(false);
    const [snErr, setSNErr] = useState(false);
    const [SNErrMessage, setSNErrMessage] = useState('');
    const [agencyList, setAgencyList] = useState([]);
    const [selectionList, setSelectionList] = useState({});
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [agencyOption, setAgencyOption] = useState([]);
    const [deviceSn, setDeviceSn] = useState();
    const [assetName, setAssetName] = useState();
    const [selectedAssetType, setSelectedAssetType] = useState();
    const [descriptionValue, setDescriptionValue] = useState([]);
    const [triggerSnackbar, settriggerSnackbar] = useState(false);
    const [textTitleSnackbar, setTextTitleSnackbar] = useState(null);
    const [textSubSnackbar, setTextSubSnackbar] = useState(null);
    const [moduleSnackbar, setModuleSnackbar] = useState(null);
    const [agencyAssignment, setAgencyAssignment] = useState([]);
    const disabledButton = deviceSn === undefined || deviceSn.length < 1;
    const [selectedFuelSourceType, setSelectedFuelSourceType] = useState();
    const [selectedMileageSourceType, setSelectedMileageSourceType] = useState();
    const [selectedBatteryMappingPreference, setSelectedBatteryMappingPreference] = useState(null);
    const [batteryMappingOptions, setBatteryMappingOptions] = useState([]);
    const [fuelCapacity, setFuelCapacity] = useState();

    const fetchBatteryPolicies = async (params) => {
        try {
            const result = await APIGetBatteryPolicies(setLoading, token, agencyId, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data.length > 0) {
                    const reducer = data.map((x) => ({
                        value: x.oid,
                        label: x.name,
                    }));
                    setBatteryMappingOptions(reducer);
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const handleOnChange = (value) => {
        setDeviceSn(value);
        setSNErr(false);
        setSNErrMessage('');
    };

    const handleAssetNameChange = (event) => {
        setAssetName(event.target.value);
    };

    const handleBackButton = () => {
        navigate('/inventory/assetdevices', { replace: true });
    };

    const handleAgencyAssignment = (e) => {
        setAgencyAssignment(e);
    };

    const handleFuelCapacityNameChange = (e) => {
        const value = e?.target?.value;
        const sanitizedValue = value.replace(/\D/g, '').replace(/^0+(?!$)/, '');
        setFuelCapacity(sanitizedValue);
    };

    const fetchAgencies = async (params) => {
        try {
            const result = await APIGetAgencies(setLoading, token, agencyId, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data.length > 0) {
                    const reducer = data && data.length > 0 ? data.map((x) => ({
                        value: x.agencyId,
                        label: x.agencyName,
                        subtitle: `@${x.agencyId}`,
                        agencyUUID: x.oid,
                    })) : [];
                    setAgencyList(reducer);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const createDevice = async () => {
        const params = {
            devices: [{
                deviceSerial: deviceSn,
                agencyOid: agencyAssignment?.agencyUUID,
                assetType: selectedAssetType?.value,
                assetName: assetName,
                description: descriptionValue,
                fuelSource: selectedFuelSourceType?.value,
                mileageSource: selectedMileageSourceType?.value,
                batteryMappingPreference: selectedBatteryMappingPreference?.value,
                fuelCapacity: fuelCapacity,
            }],
        };

        const result = await APICreateDevice(token, setLoading, agencyId, params);
        try {
            // console.log(result);
            if (result.data.status === 200) {
                setLoading(false);
                navigate('/inventory/assetdevices', { state: { snackbarStatus: 'success', snackbarTitle: 'Success', snackbarSub: 'New Device Added!' } });
            }
            // } else {
            //     settriggerSnackbar(true);
            //     setTextTitleSnackbar('Error');
            //     setTextSubSnackbar(`${result.error.code}:${result.error.message}`);
            //     setModuleSnackbar('error');
            // }
        } catch (err) {
            const { response } = result;
            const { data } = response;
            const { error } = data;
            const { code } = error;
            if (code === 4003) {
                setLoading(false);
                setSNErr(true);
                setSNErrMessage(error.message);
            }
        }
    };

    useEffect(() => {
        const params = {
            pageNumber: 1,
            totalPerPage: 100,
        };
        if (token) {
            fetchAgencies(params);
            fetchBatteryPolicies(params);
        }
    }, []);

    return (
        <div className="add-device-container">
            <div className="add-device-header-section">
                <div className="add-device-header-container">
                    <BackButton label="Back to inventory" btnClickBack={() => handleBackButton()} color="#FFFFFF" />
                </div>
            </div>
            <div className="add-device-form-section">
                <div className="add-device-form-container">
                    <div className="add-device-form-header">
                        <div className="add-device-form-header-content">
                            <Title title="Add device" deleteSubtitle titleSize="18px" lineHeight="28px" />
                            <div className="add-device-button-container">
                                <SecondaryButton label="Cancel" onClickFunction={() => handleBackButton()} />
                                <PrimaryButton label="Save" onClickFunction={() => createDevice()} disable={disabledButton} />
                            </div>
                        </div>
                        <ColoredLine />
                    </div>
                    <div className="add-device-form-content">
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-required-field">
                                    Device Serial Number
                                </div>
                                <div className="add-device-form-supporting-text">
                                    This cannot be modified at a later stage
                                </div>
                            </div>
                            <div className="add-device-input">
                                <TextInput
                                  label="Plain"
                                  error={snErr}
                                  setError={setSNErr}
                                  width="30.5rem"
                                  type="text"
                                  onChangeFunction={(e) => handleOnChange(e.target.value)}
                                />
                                {SNErrMessage && (
                                    <div className="add-device-error-message">{SNErrMessage}</div>
                                )}
                            </div>
                        </div>
                        <ColoredLine />
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Agency Assignment
                                </div>
                            </div>
                            <div className="add-device-input">
                                <ClearableDropdown agencyOption={agencyList} placeholder="Select agency workspace" handleChange={(e) => handleAgencyAssignment(e)} />
                            </div>
                        </div>
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Asset Name
                                </div>
                                <div className="add-device-form-supporting-text">
                                    Optional. Use this field to preassign an asset name on behalf of the agency.
                                </div>
                            </div>
                            <input name="assetName" autoComplete="off" value={assetName} onChange={handleAssetNameChange} className="asset-name-input" />
                        </div>
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Asset Type
                                </div>
                                <div className="add-device-form-supporting-text">
                                    Optional. Use this field to preassign an asset type on behalf of the agency.
                                </div>
                            </div>
                            <DropDownList placeholder="Select asset type" selectedValue={selectedAssetType} setSelectedValue={setSelectedAssetType} options={assetTypeOptions} />
                        </div>
                        {/* Battery Mapping Preferences Input Field */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Battery Mapping Preferences
                                </div>
                                {/* <div className="add-device-form-supporting-text">
                                    Optional. Use this field to preassign an asset type on behalf of the agency.
                                </div> */}
                            </div>
                            <DropDownList
                              placeholder="Select battery mapping"
                              selectedValue={selectedBatteryMappingPreference} // Pass the selected value
                              setSelectedValue={setSelectedBatteryMappingPreference} // Pass the setter function
                              options={batteryMappingOptions} // Pass the fetched options
                            />
                        </div>
                        {/* Fuel Source Dropdown Input Field */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Fuel Source
                                </div>
                                {/* <div className="add-device-form-supporting-text">
                                    Optional. Use this field to preassign an asset type on behalf of the agency.
                                </div> */}
                            </div>
                            <DropDownList placeholder="Select fuel source" selectedValue={selectedFuelSourceType} setSelectedValue={setSelectedFuelSourceType} options={fuelTypeOptions} />
                        </div>
                        {/* Mileage Source Input Field */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Mileage Source
                                </div>
                                {/* <div className="add-device-form-supporting-text">
                                    Optional. Use this field to preassign an asset type on behalf of the agency.
                                </div> */}
                            </div>
                            <DropDownList placeholder="Select mileage source" selectedValue={selectedMileageSourceType} setSelectedValue={setSelectedMileageSourceType} options={mileageTypeOptions} />
                        </div>
                        {/* Fuel Capacity Input Field */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Fuel Capacity
                                </div>
                                <div className="add-device-form-supporting-text">
                                    The maximum amount of fuel the asset&apos;s tank can hold, measured in liters.
                                </div>
                            </div>
                            <input name="fuelCapacity" autoComplete="off" value={fuelCapacity} onChange={handleFuelCapacityNameChange} className="asset-name-input" />
                        </div>
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Internal Admin Notes
                                </div>
                                <div className="add-device-form-supporting-text">
                                    Optional. Add a brief description to further identify the asset or for specific notes.
                                </div>
                            </div>
                            <TextArea descriptionValue={descriptionValue} setDescriptionValue={setDescriptionValue} />
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="add-device-footer">
                        <div className="add-device-button-container">
                            <SecondaryButton label="Cancel" onClickFunction={() => handleBackButton()} />
                            <PrimaryButton label="Save" onClickFunction={() => createDevice()} disable={disabledButton} />
                        </div>
                    </div>
                </div>
            </div>
            {
                triggerSnackbar && (
                <div className="geofence-snackbar-container">
                    <SnackBar state={triggerSnackbar} setState={settriggerSnackbar} title={textTitleSnackbar} subtitile={textSubSnackbar} module={moduleSnackbar} action={moduleSnackbar} />
                </div>
                )
            }
        </div>
    );
};

export default AddDevice;
