/* eslint-disable */
import React from 'react';
import InputText from '../inputText/InputText';

const BatteryEquation = ({ values, onChange, disabled }) => {
    const handleChange = (field, value) => {
        onChange(field, value);
    };

    return (
        <div
            style={{
                marginTop: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                whiteSpace: 'nowrap',
                border: '0.063rem solid #EAECF0',
                borderRadius: '0.75rem',
                boxShadow: '0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06)',
                padding: '1rem',
                backgroundColor: '#fff',
                width: 'fit-content',
                maxWidth: '600px',
                minWidth: '400px',
                opacity: disabled ? 0.5 : 1,
                pointerEvents: disabled ? 'none' : 'auto',
            }}
        >
            {/* Equation Row */}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                <div
                    className="g-detail-action-checkbox-text-header"
                    style={{
                        marginRight: '0.5rem',
                        fontSize: '1.2rem',
                    }}
                >
                    Battery Percentage (%) =
                </div>
                <InputText
                    placeholder="0"
                    value={values.a2 || ''}
                    onChange={(e) => handleChange('a2', e.target.value)}
                    width="1.5rem"
                    disabled={disabled}
                    title="A₂"
                    titleStyle={{ marginTop: '0.2rem' }}
                />
                <div className="g-detail-action-checkbox-text-header" style={{ marginLeft: '0.5rem', marginRight: '0.5rem', fontStyle: 'italic' }}>
                    V² +
                </div>
                <InputText
                    placeholder="0"
                    value={values.a1 || ''}
                    onChange={(e) => handleChange('a1', e.target.value)}
                    width="1.5rem"
                    disabled={disabled}
                    title="A₁"
                    titleStyle={{ marginTop: '0.2rem' }}
                />
                <div className="g-detail-action-checkbox-text-header" style={{ marginLeft: '0.5rem', marginRight: '0.5rem', fontStyle: 'italic' }}>
                    V +
                </div>
                <InputText
                    placeholder="0"
                    value={values.a0 || ''}
                    onChange={(e) => handleChange('a0', e.target.value)}
                    width="1.5rem"
                    disabled={disabled}
                    title="A₀"
                    titleStyle={{ marginTop: '0.2rem' }}
                />
            </div>

            {/* Additional Text Below the Equation */}
            <div style={{ marginTop: '0.5rem', fontStyle: 'italic', fontSize: '0.9rem', color: '#344054' }}>
                <div>V = Battery Voltage</div>
                <div>A = User Variable</div>
            </div>
        </div>
    );
};

export default BatteryEquation;
