/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Select, { components, PlaceholderProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import TrainIcon from './img/train.svg';
import TruckIcon from './img/truck.svg';
import './iconDropDown.css';

const customStyles = {
    control: (base) => ({
        ...base,
        height: 44,
        minHeight: 44,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        border: '1px solid #D0D5DD',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        zIndex: 2,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      position: 'absolute',
    }),
    menuPortal: (base) => ({
      ...base,
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      zIndex: 9999,
  }),
    menuList: (base) => ({
      ...base,
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#F9FAFB' : state.isFocused ? '#F9FAFB' : 'transparent',
        cursor: 'pointer',
        ':active': '#F9FAFB',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'transparent',
    }),
};

export default function DropDownList(props) {
  const {
    selectedValue,
    setSelectedValue,
    options,
    placeholder,
    width = '512px',
  } = props;

  const animatedComponents = makeAnimated();

  const handleChange = (e) => {
    setSelectedValue(e);
  };

  return (
    <div style={{ width: width, height: '44px' }}>
      <Select
        placeholder={placeholder}
        options={options}
        value={selectedValue}
        styles={customStyles}
        onChange={handleChange}
        getOptionLabel={(e) => (
          <div className="menu-container">
            {e.icon && (
              <div className="menu-icon">
                {e.icon}
              </div>
            )}
            <div className="menu-text">
              {e.label}
            </div>
            <div className="menu-subtitle">
              {e.subtitle}
            </div>
          </div>
        )}
        components={{
          IndicatorSeparator: () => null,
          animatedComponents,
        }}
        isOptionSelected={(option) => (selectedValue ? option.value === selectedValue.value : false)}
      />
    </div>
  );
}
