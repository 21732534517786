import React from 'react';
import InventoryEmptyIcon from './img/assetDevicesEmpty.svg';
import Text from '../text/Text';
import './tableemptystate.css';

const InventoryTableEmptyState = (props) => {
    const { text } = props;
  return (
    <div className="empty-state-container">
      <img loading="lazy" src={InventoryEmptyIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
      <div className="empty-state-text">
          <Text text="No Battery Policies" size="1.25rem" weight="600" align="center" height="auto" lineHeight="30px" />
          <Text text="Your search did not match any battery policies. Newly added battery policies will appear momentarily" size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
      </div>
    </div>
  );
};

export default InventoryTableEmptyState;
