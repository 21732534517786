/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { BackButton, PrimaryButton, SecondaryButton } from '../../../../component/buttonComponent/ButtonComponent';
import ColoredLine from '../../../../component/coloredLine/ColoredLine';
import Title from '../../../../component/title/Title';
import TextInput from '../../../../component/textInput/TextInput';
import { APICreateBatteryPolicy } from '../../../../config/restAPI/PostAPI';
import { APIUpdateBatteryPolicy } from '../../../../config/restAPI/PatchAPI';
import TextArea from '../../../../component/textAreaLimit/TextArea';
import './addBatteryConfig.css';
import Checkbox from '../../../../component/checkbox/Checkbox';
import Text from '../../../../component/text/Text';
import VoltageMapTable from '../../../../component/mapBattery/mapBattery';
import BatteryEquation from '../../../../component/batteryEquation/batteryEquation';
import BatteryPercentageStatusTable from '../../../../component/mapBattery/percentageBattery';
import { OutlinedDeleteButton } from '../../../../component/buttonComponent/ButtonComponent';

const AddBatteryConfig = () => {
    const navigate = useNavigate();
    const token = Cookies.get('jwtToken');
    const { state } = useLocation();
    const agencyId = Cookies.get('agencyUUID');
    
    // State for form fields
    const [loading, setLoading] = useState(false);
    const [snErr, setSNErr] = useState(false);
    const [SNErrMessage, setSNErrMessage] = useState('');
    const [batteryPolicyName, setBatteryPolicyName] = useState('');
    const [batteryVoltage, setBatteryVoltage] = useState('');
    const [batteryCapacity, setBatteryCapacity] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    
    // State for mapping options
    const [tableMapEnabled, setTableMapEnabled] = useState(false);
    const [equationMapEnabled, setEquationMapEnabled] = useState(false);
    const [voltageMaps, setVoltageMaps] = useState([]);
    const [equationMap, setEquationMap] = useState({ a2: 0, a1: 0, a0: 0 });
    const [percentageMaps, setPercentageMaps] = useState([
        { percentageFrom: 100, percentageTo: 80, state: 'Excellent' },
        { percentageFrom: 79, percentageTo: 50, state: 'Good' },
        { percentageFrom: 49, percentageTo: 20, state: 'Poor' },
        { percentageFrom: 20, percentageTo: 0, state: 'Replacement / Charging Required' },
    ]);
    
    // State for edit mode and delete modal
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentPolicyId, setCurrentPolicyId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    
    // Initialize form with existing data if in edit mode
    useEffect(() => {
        if (state) {
            const policy = state
            console.log(policy);
            setIsEditMode(true);
            setCurrentPolicyId(policy.id);
            
            // Set basic fields
            setBatteryPolicyName(policy.name);
            setBatteryVoltage(policy.batteryVoltage || '');
            setBatteryCapacity(policy.batteryCapacity || '');
            setDescriptionValue(policy.description || '');
            
            // Set mapping options
            setTableMapEnabled(policy.tableMap?.enabled || false);
            setEquationMapEnabled(policy.equationMap?.enabled || false);
            
            // Set voltage maps if table mapping is enabled
            if (policy.tableMap?.enabled && policy.tableMap.mapping) {
                setVoltageMaps(policy.tableMap.mapping);
            }
            
            // Set equation map if equation mapping is enabled
            if (policy.equationMap?.enabled && policy.equationMap.mapping) {
                setEquationMap(policy.equationMap.mapping);
            }
            
            // Set percentage maps
            if (policy.percentageMapping) {
                setPercentageMaps(policy.percentageMapping);
            }
        }
    }, [state]);

    // Handlers for form fields
    const handleBatteryPolicyNameChange = (value) => {
        setBatteryPolicyName(value);
    };

    const handleBatteryVoltageChange = (value) => {
        setBatteryVoltage(value);
    };

    const handleBatteryCapacityChange = (value) => {
        setBatteryCapacity(value);
    };

    // Handlers for mapping toggles
    const handleTableMapToggle = (e) => {
        setTableMapEnabled(e.target.checked);
        if (e.target.checked) {
            setEquationMapEnabled(false);
        }
    };

    const handleEquationMapToggle = (e) => {
        setEquationMapEnabled(e.target.checked);
        if (e.target.checked) {
            setTableMapEnabled(false);
        }
    };

    const handleEquationChange = (field, value) => {
        setEquationMap(prev => ({ ...prev, [field]: value }));
    };

    // Navigation handlers
    const handleBackButton = () => {
        navigate('/inventory/batteryconfigurator', { replace: true });
    };

    const handleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    // API call handlers
    const handleCreateOrUpdatePolicy = async () => {
        const params = {
            batteryPolicy: {
                name: batteryPolicyName,
                batteryVoltage: parseFloat(batteryVoltage),
                batteryCapacity: parseFloat(batteryCapacity),
                description: descriptionValue,
                tableMap: {
                    enabled: tableMapEnabled,
                    mapping: tableMapEnabled ? voltageMaps : [],
                },
                equationMap: {
                    enabled: equationMapEnabled,
                    mapping: equationMapEnabled ? equationMap : { a2: 0, a1: 0, a0: 0 },
                },
                percentageMapping: percentageMaps,
            },
        };

        try {
            let result;
            if (isEditMode) {
                result = await APIUpdateBatteryPolicy(token, setLoading, agencyId, currentPolicyId, params);
            } else {
                result = await APICreateBatteryPolicy(token, setLoading, agencyId, params);
            }

            if (result.data.status === 200) {
                setLoading(false);
                navigate('/inventory/batteryconfigurator', {
                    state: {
                        snackbarStatus: 'success',
                        snackbarTitle: 'Success',
                        snackbarSub: isEditMode ? 'Battery Policy Updated!' : 'New Battery Policy Added!',
                    },
                });
            }
        } catch (err) {
            const { response } = err;
            if (response && response.data) {
                const { data } = response;
                const { error } = data;
                const { code, message } = error;
                if (code === 4003) {
                    setLoading(false);
                    setSNErr(true);
                    setSNErrMessage(message);
                }
            } else {
                setLoading(false);
                setSNErr(true);
                setSNErrMessage('An unexpected error occurred. Please try again.');
            }
        }
    };

    const handleDeletePolicy = async () => {
        try {
            const result = await APIDeleteBatteryPolicy(token, setLoading, agencyId, currentPolicyId);
            if (result.data.status === 200) {
                setLoading(false);
                navigate('/inventory/batteryconfigurator', {
                    state: {
                        snackbarStatus: 'success',
                        snackbarTitle: 'Success',
                        snackbarSub: 'Battery Policy Deleted!',
                    },
                });
            }
        } catch (err) {
            setLoading(false);
            setSNErr(true);
            setSNErrMessage('Failed to delete battery policy. Please try again.');
        }
    };

    return (
        <div className="add-device-container">
            <div className="add-device-header-section">
                <div className="add-device-header-container">
                    <BackButton label="Back to inventory" btnClickBack={() => handleBackButton()} color="#FFFFFF" />
                </div>
            </div>
            <div className="add-device-form-section">
                <div className="add-device-form-container">
                    <div className="add-device-form-header">
                        <div className="add-device-form-header-content">
                            <Title 
                                title={isEditMode ? "Edit Battery Configuration" : "Add Battery Configuration"} 
                                deleteSubtitle 
                                titleSize="18px" 
                                lineHeight="28px" 
                            />
                        </div>
                        <ColoredLine />
                    </div>
                    <div className="add-device-form-content">
                        {/* Battery Policy Name */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-field">
                                    Battery Policy Name
                                </div>
                            </div>
                            <div className="add-device-input">
                                <TextInput
                                    label="Plain"
                                    error={snErr}
                                    errorMessage={SNErrMessage}
                                    setError={setSNErr}
                                    width="30.5rem"
                                    type="text"
                                    value={batteryPolicyName}
                                    onChangeFunction={(e) => handleBatteryPolicyNameChange(e.target.value)}
                                />
                            </div>
                        </div>
                        {/* Battery Voltage */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-field">
                                    Battery Voltage
                                </div>
                                <div className="add-device-form-supporting-text">
                                    Input the battery voltage in Volts (V)
                                </div>
                            </div>
                            <div className="add-device-input">
                                <TextInput
                                    label="Plain"
                                    error={snErr}
                                    errorMessage={SNErrMessage}
                                    setError={setSNErr}
                                    width="30.5rem"
                                    type="number"
                                    value={batteryVoltage}
                                    onChangeFunction={(e) => handleBatteryVoltageChange(e.target.value)}
                                />
                            </div>
                        </div>
                        {/* Battery Capacity */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-field">
                                    Battery Capacity
                                </div>
                                <div className="add-device-form-supporting-text">
                                    Input the battery capacity in mAh
                                </div>
                            </div>
                            <div className="add-device-input">
                                <TextInput
                                    label="Plain"
                                    error={snErr}
                                    errorMessage={SNErrMessage}
                                    setError={setSNErr}
                                    width="30.5rem"
                                    type="number"
                                    value={batteryCapacity}
                                    onChangeFunction={(e) => handleBatteryCapacityChange(e.target.value)}
                                />
                            </div>
                        </div>
                        {/* Description */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-form-main-text">
                                    Description
                                </div>
                                <div className="add-device-form-supporting-text">
                                    Optional. Add a brief description to further identify the battery configuration.
                                </div>
                            </div>
                            <TextArea 
                                descriptionValue={descriptionValue} 
                                setDescriptionValue={setDescriptionValue} 
                            />
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="add-device-form-content">
                        {/* Battery Mapping */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-field">
                                    Battery Mapping
                                </div>
                                <div className="add-device-form-supporting-text">
                                    Map the Voltage to Percentages.
                                </div>
                            </div>
                            <div className="add-device-input">
                                {/* Map by Table */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ position: 'relative', top: '3px', marginRight: '8px' }}>
                                        <Checkbox
                                            width="16px"
                                            height="16px"
                                            borderRadius="4px"
                                            name="tableMap"
                                            checked={tableMapEnabled}
                                            onChange={handleTableMapToggle}
                                            disabled={equationMapEnabled}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Text
                                            text="Map by Table"
                                            weight="500"
                                            size="0.875rem"
                                            height="1.25rem"
                                            color="#344054"
                                        />
                                    </div>
                                </div>
                                <div className="g-detail-action-checkbox-text-header" style={{ marginLeft: '24px' }}>
                                    Map the battery voltages to percentages manually
                                </div>
                                <div style={{ marginTop: '16px' }}>
                                    <VoltageMapTable
                                        voltageMaps={voltageMaps}
                                        setVoltageMaps={setVoltageMaps}
                                        disabled={!tableMapEnabled}
                                    />
                                </div>
                                {/* Map by Equation */}
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                    <div style={{ position: 'relative', top: '3px', marginRight: '8px' }}>
                                        <Checkbox
                                            width="16px"
                                            height="16px"
                                            borderRadius="4px"
                                            name="equationMap"
                                            checked={equationMapEnabled}
                                            onChange={handleEquationMapToggle}
                                            disabled={tableMapEnabled}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Text
                                            text="Map by Equation"
                                            weight="500"
                                            size="0.875rem"
                                            height="1.25rem"
                                            color="#344054"
                                        />
                                    </div>
                                </div>
                                <div className="g-detail-action-checkbox-text-header" style={{ marginLeft: '24px' }}>
                                    Map the battery voltages to percentages via a predefined equation
                                </div>
                                <div style={{ marginTop: '16px' }}>
                                    <BatteryEquation
                                        values={equationMap}
                                        onChange={handleEquationChange}
                                        disabled={!equationMapEnabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="add-device-form-content">
                        {/* Percentage Mapping */}
                        <div className="add-device-form-row">
                            <div className="add-device-form-text-container">
                                <div className="add-device-field">
                                    Percentage Mapping
                                </div>
                                <div className="add-device-form-supporting-text">
                                    The percentages will be mapped according to the given table.
                                </div>
                            </div>
                            <div className="add-device-input">
                                <BatteryPercentageStatusTable 
                                    percentageMaps={percentageMaps} 
                                    setPercentageMaps={setPercentageMaps} 
                                />
                            </div>
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="add-device-footer">
                        <div className="add-device-button-container">
                            <SecondaryButton label="Cancel" onClickFunction={() => handleBackButton()} />
                            {isEditMode && (
                                <OutlinedDeleteButton label="Delete Policy" onClickFunction={handleDeleteModal} />
                            )}
                            <PrimaryButton 
                                label={isEditMode ? "Update" : "Save"} 
                                onClickFunction={handleCreateOrUpdatePolicy} 
                                disable={!batteryPolicyName || !batteryVoltage || !batteryCapacity} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {deleteModal && (
                <ConfirmationModal
                    title="Delete Battery Policy"
                    subtitle="Are you sure you want to delete this battery policy?"
                    confirmText="Delete"
                    cancelText="Cancel"
                    onConfirm={() => {
                        handleDeletePolicy();
                        setDeleteModal(false);
                    }}
                    onCancel={() => setDeleteModal(false)}
                />
            )}
        </div>
    );
};

export default AddBatteryConfig;
