/* eslint-disable */
import React, { useState } from 'react';
import './mapBattery.css';

const VoltageMapTable = ({ voltageMaps, setVoltageMaps, disabled }) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [newVoltageFrom, setNewVoltageFrom] = useState('');
    const [newVoltageTo, setNewVoltageTo] = useState('');
    const [newPercentage, setNewPercentage] = useState('');

    const handleSave = (index) => {
        if (disabled) return;
        const updatedVoltageMaps = [...voltageMaps];
        updatedVoltageMaps[index] = {
            ...updatedVoltageMaps[index],
            voltageFrom: parseFloat(newVoltageFrom),
            voltageTo: parseFloat(newVoltageTo),
            percentage: parseFloat(newPercentage),
        };
        setVoltageMaps(updatedVoltageMaps);
        setEditingIndex(null);
        setIsAddingNew(false);
        setNewVoltageFrom('');
        setNewVoltageTo('');
        setNewPercentage('');
    };

    const handleAddNew = () => {
        if (disabled) return;
        setIsAddingNew(true);
        setNewVoltageFrom('');
        setNewVoltageTo('');
        setNewPercentage('');
    };

    const handleSaveNew = () => {
        if (disabled) return;
        const newVoltageMap = {
            id: voltageMaps.length + 1,
            voltageFrom: parseFloat(newVoltageFrom),
            voltageTo: parseFloat(newVoltageTo),
            percentage: parseFloat(newPercentage),
        };
        setVoltageMaps([...voltageMaps, newVoltageMap]);
        setIsAddingNew(false);
        setNewVoltageFrom('');
        setNewVoltageTo('');
        setNewPercentage('');
    };

    const handleCancel = () => {
        setIsAddingNew(false);
        setEditingIndex(null);
        setNewVoltageFrom('');
        setNewVoltageTo('');
        setNewPercentage('');
    };

    const handleDelete = (index) => {
        if (disabled) return;
        const updatedVoltageMaps = voltageMaps.filter((_, i) => i !== index);
        setVoltageMaps(updatedVoltageMaps);
    };

    return (
      <div className="voltage-map-table-container" style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}>
        <div className="voltage-map-table-header">
            <div>Voltage From (V)</div>
            <div>Voltage To (V)</div>
            <div>Percentage (%)</div>
            <div>Actions</div>
        </div>
        <div className="voltage-map-table-body-list-container">
            {voltageMaps.map((map, index) => (
                <div
                    key={map.id}
                    className={
                        index === voltageMaps.length - 1 && !isAddingNew
                            ? 'voltage-map-table-body'
                            : 'voltage-map-table-body-with-divider'
                    }
                >
                    {editingIndex === index ? (
                        <>
                            <input
                                type="number"
                                value={newVoltageFrom}
                                onChange={(e) => setNewVoltageFrom(e.target.value)}
                                placeholder={map.voltageFrom.toString()}
                                className="voltage-input"
                                disabled={disabled}
                            />
                            <input
                                type="number"
                                value={newVoltageTo}
                                onChange={(e) => setNewVoltageTo(e.target.value)}
                                placeholder={map.voltageTo.toString()}
                                className="voltage-input"
                                disabled={disabled}
                            />
                            <input
                                type="number"
                                value={newPercentage}
                                onChange={(e) => setNewPercentage(e.target.value)}
                                placeholder={map.percentage.toString()}
                                className="voltage-input"
                                disabled={disabled}
                            />
                            <div className="voltage-map-table-remove-button">
                                <button onClick={() => handleSave(index)} disabled={disabled}>Save</button>
                                <button onClick={handleCancel} disabled={disabled}>Cancel</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="voltage-map-table-data">{map.voltageFrom}</div>
                            <div className="voltage-map-table-data">{map.voltageTo}</div>
                            <div className="voltage-map-table-data">{map.percentage}%</div>
                            <div className="voltage-map-table-remove-button">
                                <button onClick={() => handleDelete(index)} disabled={disabled}>Delete</button>
                                <button
                                    onClick={() => {
                                        if (disabled) return;
                                        setEditingIndex(index);
                                        setNewVoltageFrom(map.voltageFrom);
                                        setNewVoltageTo(map.voltageTo);
                                        setNewPercentage(map.percentage);
                                    }}
                                    disabled={disabled}
                                >
                                    Edit
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ))}
            {isAddingNew && (
                <div className="voltage-map-table-body-with-divider">
                    <input
                        type="number"
                        value={newVoltageFrom}
                        onChange={(e) => setNewVoltageFrom(e.target.value)}
                        placeholder="00.00"
                        className="voltage-input"
                        disabled={disabled}
                    />
                    <input
                        type="number"
                        value={newVoltageTo}
                        onChange={(e) => setNewVoltageTo(e.target.value)}
                        placeholder="00.00"
                        className="voltage-input"
                        disabled={disabled}
                    />
                    <input
                        type="number"
                        value={newPercentage}
                        onChange={(e) => setNewPercentage(e.target.value)}
                        placeholder="0"
                        className="voltage-input"
                        disabled={disabled}
                    />
                    <div className="voltage-map-table-remove-button">
                        <button onClick={handleSaveNew} disabled={disabled}>Save</button>
                        <button onClick={handleCancel} disabled={disabled}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
        <div className="voltage-map-table-button" onClick={handleAddNew} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
            <div>Add New Voltage Map</div>
        </div>
      </div>
    );
};

export default VoltageMapTable;