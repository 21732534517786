/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/system';
import DataTable from 'react-data-table-component';
import Checkbox from '../checkbox/Checkbox';
import InventoryPagination from './pagination/InventoryPagination/InventoryPagination';
import TeamsPagination from './pagination/TeamsPagination/TeamsPagination';
import { inventoryColumn } from './column/ColumnInventory';
import { telemetryColumn } from './column/ColumnTelemetry';
import { settingsColumn } from './column/ColumnTeamSetting';
import { geofenceColumn } from './column/ColumnGeofence';
import { telemetryLogColumn } from './column/ColumnTelemetryLog';
import { agenciesColumn } from './column/ColumnAgencies';
import { agencyUsersColumn } from './column/ColumnAgencyUsers';
import { adminInventoryColumn } from './column/ColumnAdminInventory';
import { connectivityColumn } from './column/ColumnConnectivity';
import { notificationColumn } from './column/ColumnNotification';
import { BatteryConfiguratorColumn } from './column/ColumnBatteryConfigurator';
import AgencyUsersEmptyState from '../tableEmptyState/AgencyUsersEmptyState';
import TelemetrySShotEmptyState from '../tableEmptyState/TelemetrySShotEmptyState';

// import
import './Table.css';

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#365A6D',
        },
    },
});

const sortIcon = <ArrowDownwardIcon />;

const Table = (props) => {
    const {
    tableData, checkbox, pagination, module, rowDisabledCriteria,
    striped, customStyles,
    handlePerRowsChange,
    setSelectedRowCount,
    loading,
    totalRows,
    handlePageChange,
    backgroundColor,
    rowStyles,
    test,
    } = props;

    const tableCustomStyles = {
        headCells: {
            style: {
                backgroundColor: backgroundColor,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '18px',
                padding: '13px 0px 13px 24px',
            },
        },
        cells: {
            style: {
                padding: '16px 0px 16px 24px',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                borderBottom: '0.0625rem solid #EAECF0',
            },
            stripedStyle: {
                backgroundColor: '#F9FAFB',
            },
          },
    };

    const columnsRender = (value) => {
        switch (value) {
            case 'inventory':
                return inventoryColumn;
            case 'telemetry':
                return telemetryColumn;
            case 'teams':
                return settingsColumn;
            case 'geofence':
                return geofenceColumn;
            case 'connectivity':
                return connectivityColumn;
            case 'telemetryLog':
                return telemetryLogColumn;
            case 'agencies':
                return agenciesColumn;
            case 'agencyUsers':
                return agencyUsersColumn;
            case 'adminInventory':
                return adminInventoryColumn;
            case 'notification':
                return notificationColumn;
            case 'batteryConfigurator':
                return BatteryConfiguratorColumn;
            default:
                return null;
        }
    };

    const paginationRender = (value) => {
        switch (value) {
            case 'inventory':
                return InventoryPagination;
            case 'teams':
                return InventoryPagination;
            case 'geofence':
                return InventoryPagination;
            case 'connectivity':
                return InventoryPagination;
            case 'agencies':
                return InventoryPagination;
            case 'agencyUsers':
                return InventoryPagination;
            case 'adminInventory':
                return InventoryPagination;
            case 'notification':
                return InventoryPagination;
            case 'batteryConfigurator':
                return InventoryPagination;
            default:
                return null;
        }
    };

    const emptyTableState = (value) => {
        switch (value) {
            case 'agencyUsers':
                return <AgencyUsersEmptyState />;
            default:
                return null;
        }
    };

    const CustomLoader = () => (
        <div style={{ padding: '24px', margin: 'auto', height: 'auto' }}>
            <ThemeProvider theme={loadTheme}>
                <CircularProgress color="amcolor" variant="indeterminate" />
            </ThemeProvider>
        </div>
    );

    const handleRowChange = (e) => {
        setSelectedRowCount(e.selectedCount);
    };

    return (
        <DataTable
          columns={columnsRender(module)}
          data={tableData}
          sortIcon={sortIcon}
          fixedHeader
          progressPending={loading}
          progressComponent={<CustomLoader />}
          pagination={!!pagination}
          paginationServer
          paginationComponent={paginationRender(module)}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          customStyles={tableCustomStyles}
          conditionalRowStyles={rowStyles}
          noDataComponent={emptyTableState(module)}
          striped={striped}
        />
    );
};

export default Table;
