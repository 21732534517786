/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Popover } from '@mui/material';
import VerticalDots from '../img/verticalDots.svg';
import MenuPopup from '../../menuPopup/MenuPopup';
import './ColumnBatteryConfigurator.css';

const CustomTitle = ({ row }) => {
    const { name } = row;
    return (
        <div>
            { }
            <div>
                <b>
                {name}
                </b>
            </div>
        </div>
    );
};

const CustomVoltage = ({ row }) => {
    const { batteryVoltage } = row;
    return (
        <div>
            { }
            <div>
                {batteryVoltage}
                {' '}
                V
            </div>
        </div>
    );
};

const CustomCapacity = ({ row }) => {
    const { batteryCapacity } = row;
    return (
        <div>
            { }
            <div>
                {batteryCapacity}
                {' '}
                mAh
            </div>
        </div>
    );
};

const CustomDateCreated = ({ row }) => {
    const {
        dateCreated,
    } = row;

    return (
        <div>
            {dateCreated !== null ? (
                <>
                    <div className="table-date-text">{dateCreated.date}</div>
                    <div>
                        <div
                          className="table-time-text"
                          data-tag="allowRowEvents"
                          style={{
                            color: 'grey',
                            overflow: 'hidden',
                            whiteSpace: 'wrap',
                            textOverflow: 'ellipses',
                          }}
                        >
                            {dateCreated.time}
                        </div>
                    </div>
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
};

const CustomMenuButton = (assets) => {
    const { row, rowIndex } = assets;
    const [anchorEl, setAnchorEl] = useState(null);
    const [assetRow, setAssetRow] = useState();
    const handleClick = (event) => {
        setAssetRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAssetRow();
        setAnchorEl(null);
    };

    return (
        <div>
            <div className="assets-button" id={rowIndex} aria-hidden="true" onClick={handleClick}>
                <div className="assets-menu">
                    <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                </div>
            </div>
            <Popover
              id={rowIndex}
              sx={{
                boxShadow: 2,
                borderRadius: 2,
            }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <MenuPopup page="adminBatteryConfigurator" assetDetails={assetRow} />
            </Popover>
        </div>
    );
};

export const BatteryConfiguratorColumn = [
    {
        name: 'Battery Policy Name',
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => <CustomTitle row={row} />,
    },
    {
        name: 'Battery Voltage',
        selector: (row) => row.batteryVoltage,
        sortable: true,
        cell: (row) => <CustomVoltage row={row} />,
    },
    {
        name: 'Charge',
        selector: (row) => row.batteryCapacity,
        sortable: true,
        cell: (row) => <CustomCapacity row={row} />,
    },
    {
        name: 'Assets with Policy',
        selector: (row) => row?.totalAssets,
        sortable: true,
    },
    {
        name: 'Date Created',
        selector: (row) => row?.dateCreated?.date,
        sortable: true,
        cell: (row) => <CustomDateCreated row={row} />,
    },
    {
        cell: (row, rowIndex) => (
              <CustomMenuButton row={row} rowIndex={rowIndex} />
        ),
        allowOverflow: true,
        button: true,
        width: '48px',
    },
];
