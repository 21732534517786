/* eslint-disable */
import React, { useState } from 'react';
import './percentageBattery.css';

export default function BatteryPercentageStatusTable({ percentageMaps, setPercentageMaps }) {
    const [editingIndex, setEditingIndex] = useState(null);
    const [newPercentageFrom, setNewPercentageFrom] = useState('');
    const [newPercentageTo, setNewPercentageTo] = useState('');
    const [newState, setNewState] = useState('');

    const handleSave = (index) => {
        const updatedPercentageStatus = [...percentageMaps];
        updatedPercentageStatus[index] = {
            ...updatedPercentageStatus[index],
            percentageFrom: parseFloat(newPercentageFrom),
            percentageTo: parseFloat(newPercentageTo),
            state: newState,
        };
        setPercentageMaps(updatedPercentageStatus);
        setEditingIndex(null);
        setNewPercentageFrom('');
        setNewPercentageTo('');
        setNewState('');
    };

    const handleCancel = () => {
        setEditingIndex(null);
        setNewPercentageFrom('');
        setNewPercentageTo('');
        setNewState('');
    };

    return (
        <div className="percentage-battery-container">
            <div className="percentage-battery-header">
                <div>Percentage From (%)</div>
                <div>Percentage To (%)</div>
                <div>State</div>
                <div>Actions</div>
            </div>
            <div className="percentage-battery-body-list-container">
                {percentageMaps.map((status, index) => (
                    <div
                        key={index}
                        className={
                            index === percentageMaps.length - 1
                                ? 'percentage-battery-body'
                                : 'percentage-battery-body-with-divider'
                        }
                    >
                        {editingIndex === index ? (
                            <>
                                <input
                                    type="number"
                                    value={newPercentageFrom}
                                    onChange={(e) => setNewPercentageFrom(e.target.value)}
                                    placeholder={status.percentageFrom.toString()}
                                    className="percentage-battery-input"
                                />
                                <input
                                    type="number"
                                    value={newPercentageTo}
                                    onChange={(e) => setNewPercentageTo(e.target.value)}
                                    placeholder={status.percentageTo.toString()}
                                    className="percentage-battery-input"
                                />
                                <input
                                    type="text"
                                    value={newState}
                                    onChange={(e) => setNewState(e.target.value)}
                                    placeholder={status.state}
                                    className="percentage-battery-input"
                                />
                                <div className="percentage-battery-remove-button">
                                    <button onClick={() => handleSave(index)}>Save</button>
                                    <button onClick={handleCancel}>Cancel</button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="percentage-battery-data">{status.percentageFrom}</div>
                                <div className="percentage-battery-data">{status.percentageTo}</div>
                                <div className="percentage-battery-data">{status.state}</div>
                                <div className="percentage-battery-remove-button">
                                    <button onClick={() => {
                                        setEditingIndex(index);
                                        setNewPercentageFrom(status.percentageFrom);
                                        setNewPercentageTo(status.percentageTo);
                                        setNewState(status.state);
                                    }}>Edit</button>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}