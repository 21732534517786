/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import {
    Navigate, useRoutes, useNavigate, useOutletContext,
} from 'react-router-dom';
import axios from 'axios';
import { WebsocketProvider } from '../reducer/Websocket';
import Login from '../../pages/login/Login';
import AgencySelector from '../../pages/agencySelector/AgencySelector';
import MapOverview from '../../panel/live/mapOverview/MapOverview';
import TelemetrySnapshot from '../../panel/live/telemetrySnapshot/TelemetrySnapshot';
import AssetStatus from '../../panel/live/assetStatus/AssetStatus';
import CheckYourEmail from '../../pages/resetPassword/CheckYourEmail';
import ForgotPassword from '../../pages/resetPassword/ForgotPassword';
import SetNewPassword from '../../pages/resetPassword/SetNewPassword';
import InvalidLink from '../../pages/resetPassword/InvalidLink';
import PasswordReset from '../../pages/resetPassword/PasswordReset';
import ErrorPage from '../../pages/errorPage/ErrorPage';
import DashboardLayout from '../../component/dashboardLayout/DashboardLayout';
import Dashboard from '../../panel/dashboard/Dashboard';
import AssetsDevice from '../../panel/inventory/assetsDevice/AssetsDevice';
import Configuration from '../../panel/inventory/assetsDevice/configuration/General/Configuration';
import Geofence from '../../panel/inventory/geofence/Geofence';
import GeofenceDetails from '../../panel/inventory/geofence/GeofenceDetails';
import Beacons from '../../panel/inventory/beacons/Beacons';
import Connectivity from '../../panel/inventory/connectivity/Connectivity';
// eslint-disable-next-line import/no-named-as-default
import Profile from '../../panel/settings/profile/Profile';
import Teams from '../../panel/settings/teams/Teams';
import AssetPerformance from '../../panel/insights/AssetPerformance/AssetPerformance';
import TelemetryLog from '../../panel/insights/AssetPerformance/TelemetryLog/TelemetryLog';
import UpdateTeamMember from '../../panel/settings/teams/updateTeamMember/UpdateTeamMember';
import Audits from '../../panel/insights/Audits/Audits';
import IOrail from '../../panel/insights/IOrail/IOrail';
import IOroad from '../../panel/insights/IOroad/IOroad';
import SignUp from '../../pages/signup/Signup';
import VerifyEmail from '../../pages/verifyEmail/VerifyEmail';
import Security from '../../panel/settings/security/Security';
import Agencies from '../../panel/agencies/Agencies/Agencies';
import CreateAgency from '../../panel/agencies/Agencies/createAgency/CreateAgency';
import AgencyConfigurations from '../../panel/agencies/Agencies/Configurations/General/AgencyConfigurations';
import AgencyUsers from '../../panel/agencies/Agencies/Configurations/Users/AgencyUsers';
import AgencyInventory from '../../panel/agencies/Agencies/Configurations/Inventory/AgencyInventory';
import Notifications from '../../panel/settings/notifications/Notifications';
import ManageNotifications from '../../panel/settings/notifications/manageNotifications/ManageNotifications';
import InventoryConnectivity from '../../panel/inventory/assetsDevice/configuration/Connectivity/Connectivity';
import ODAnalytics from '../../panel/insights/ODAnalytics/ODAnalytics';
// import { PrivilegeContext, PrivilegeProvider } from '../reducer/Privilege';
import { APIGetUsers, APIGetV3TeamMembers } from '../restAPI/FetchAPI';
import ProtectedRoute from './protectedRouter';
import { AuthContext } from '../reducer/Auth';
import { ApiDataProvider } from '../reducer/Modules';
import AddDevice from '../../panel/inventory/assetsDevice/addDevice/addDevice';
import TripReport from '../../panel/insights/ODAnalytics/TripReport/TripReport';
import NotificationHistory from '../../panel/notifications/history/NotificationHistory';
import NotificationConfiguration from '../../panel/notifications/Configuration/NotificationConfiguration';
import NotificationConfigurationDetails from '../../panel/notifications/Configuration/NotificationConfigurationDetails/NotificationConfigurationDetails';
// eslint-disable-next-line import/no-unresolved
import ExportAlerts from '../../panel/notifications/exportAlerts/exportAlerts';
import BatteryConfig from '../../panel/inventory/batteryConfigurator/BatteryConfigurator';
import AddBatteryConfig from '../../panel/inventory/batteryConfigurator/addBatteryConfig/addBatteryConfig';

const Router = () => {
    const navigate = useNavigate();
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agencyUUID');
    const path = window.location.pathname;
    const [loading, setLoading] = useState(false);
    const context = useContext(AuthContext);
    const { logout } = context;

    const [cookiesUpdated, setCookiesUpdated] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const fetchTeamMembers = async (page) => {
        try {
            const params = {
                pageNumber: page,
                totalPerPage: perPage,
            };
            const result = await APIGetV3TeamMembers(setLoading, token, agencyId, params);
            if (result.data.status === 200) {
                const { data, totalData } = result.data;
                setTotalUsers(totalData);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    return useRoutes([
        {
            path: 'login',
            element: <Login />,

        },
        {
            path: 'signup/*',
            element: <SignUp />,
            children: [
                { index: true, element: <SignUp /> },
            ],
        },
        {
            path: 'verifyEmail/*',
            element: <VerifyEmail />,
            children: [
                { index: true, element: <VerifyEmail /> },
            ],
        },
        {
            path: 'agencySelector',
            element: <AgencySelector />,
        },
        {
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout /></WebsocketProvider></ApiDataProvider>,
            children: [
                { path: '/dashboard', element: <ProtectedRoute user="dashboard" redirectTo="/login"><Dashboard /></ProtectedRoute> },
            ],
        },
        {
            path: '/live',
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout /></WebsocketProvider></ApiDataProvider>,
            children: [
                { path: 'mapOverview', element: <ProtectedRoute user="liveMap" redirectTo="/login"><MapOverview /></ProtectedRoute> },
                { path: 'telemetrySnapshot', element: <ProtectedRoute user="telemetrySnapshot" redirectTo="/login"><TelemetrySnapshot /></ProtectedRoute> },
                { path: 'assetStatus', element: <ProtectedRoute user="assetStatus" redirectTo="/login"><AssetStatus /></ProtectedRoute> },
            ],
        },
        {
            path: '/insights',
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout /></WebsocketProvider></ApiDataProvider>,
            children: [
                { path: 'assetPerformance', element: <ProtectedRoute user="assetPerformance" redirectTo="/login"><AssetPerformance /></ProtectedRoute> },
                { path: 'assetPerformance/TelemetryLog', element: <ProtectedRoute user="assetPerformance" redirectTo="/login"><TelemetryLog /></ProtectedRoute> },
                { path: 'IOrail', element: <ProtectedRoute user="iorail" redirectTo="/login"><IOrail /></ProtectedRoute> },
                { path: 'IOroad', element: <ProtectedRoute user="ioroad" redirectTo="/login"><IOroad /></ProtectedRoute> },
                { path: 'audits', element: <Audits /> },
                { path: 'odAnalytics', element: <ProtectedRoute user="odAnalytics" redirectTo="/login"><ODAnalytics /></ProtectedRoute> },
                { path: 'odAnalytics/TripReport', element: <ProtectedRoute user="odAnalytics" redirectTo="/login"><TripReport /></ProtectedRoute> },
            ],
        },
        {
            path: '/inventory',
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout /></WebsocketProvider></ApiDataProvider>,
            children: [
                { path: 'assetdevices', element: <ProtectedRoute user="asset" redirectTo="/login"><AssetsDevice /></ProtectedRoute> },
                { path: 'assetdevices/configuration', element: <ProtectedRoute user="asset" redirectTo="/login"><Configuration /></ProtectedRoute> },
                { path: 'assetdevices/addDevice', element: <ProtectedRoute user="asset" redirectTo="/login"><AddDevice /></ProtectedRoute> },
                { path: 'assetdevices/connectivity', element: <ProtectedRoute user="asset" redirectTo="/login"><InventoryConnectivity /></ProtectedRoute> },
                // { path: 'geofence', element: <ProtectedRoute user="geofence" redirectTo="/login"><Geofence /></ProtectedRoute> },
                // { path: 'geofence/details', element: <ProtectedRoute user="geofence" redirectTo="/login"><GeofenceDetails /></ProtectedRoute> },
                { path: 'beacons', element: <ProtectedRoute user="beacon" redirectTo="/login"><Beacons /></ProtectedRoute> },
                { path: 'connectivity', element: <ProtectedRoute user="connectivity" redirectTo="/login"><Connectivity /></ProtectedRoute> },

                { path: 'batteryconfigurator', element: <ProtectedRoute user="asset" redirectTo="/login"><BatteryConfig /></ProtectedRoute> },
                { path: 'batteryconfigurator/addBatteryConfig', element: <ProtectedRoute user="batteryconfig" redirectTo="/login"><AddBatteryConfig /></ProtectedRoute> },
            ],
        },
        {
            path: '/support',
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout /></WebsocketProvider></ApiDataProvider>,
            children: [],
        },
        {
            path: '/settings',
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout cookiesUpdated={cookiesUpdated} /></WebsocketProvider></ApiDataProvider>,
            children: [
                { path: 'profile', element: <ProtectedRoute user="settings" redirectTo="/login"><Profile setCookiesUpdated={setCookiesUpdated} cookiesUpdated={cookiesUpdated} totalUsers={totalUsers} /></ProtectedRoute> },
                { path: 'security', element: <ProtectedRoute user="settings" redirectTo="/login"><Security totalUsers={totalUsers} /></ProtectedRoute> },
                { path: 'teams', element: <ProtectedRoute user="settings" redirectTo="/login"><Teams totalUsers={totalUsers} /></ProtectedRoute> },
                { path: 'teams/updateMember', element: <ProtectedRoute user="settings" redirectTo="/login"><UpdateTeamMember /></ProtectedRoute> },
                { path: 'notifications', element: <ProtectedRoute user="settings" redirectTo="/login"><Notifications totalUsers={totalUsers} /></ProtectedRoute> },
                { path: 'notifications/manageNotifications', element: <ProtectedRoute user="settings" redirectTo="/login"><ManageNotifications /></ProtectedRoute> },
            ],
        },
        {
            path: '/notifications',
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout cookiesUpdated={cookiesUpdated} /></WebsocketProvider></ApiDataProvider>,
            children: [
                { path: 'history', element: <ProtectedRoute user="notifications" redirectTo="/login"><NotificationHistory /></ProtectedRoute> },
                { path: 'configuration', element: <ProtectedRoute user="notifications" redirectTo="/login"><NotificationConfiguration /></ProtectedRoute> },
                { path: 'configuration/details', element: <ProtectedRoute user="notifications" redirectTo="/login"><NotificationConfigurationDetails /></ProtectedRoute> },
                { path: 'exportAlerts', element: <ProtectedRoute user="notifications" redirectTo="/login"><ExportAlerts /></ProtectedRoute> },
            ],
        },
        {
            path: '/agencies',
            element: <ApiDataProvider><WebsocketProvider><DashboardLayout /></WebsocketProvider></ApiDataProvider>,
            children: [
                { path: '', element: <Agencies /> },
                { path: 'createAgency', element: <CreateAgency /> },
                { path: 'Configurations/General', element: <AgencyConfigurations /> },
                { path: 'Configurations/Users', element: <AgencyUsers /> },
                { path: 'Configurations/Inventory', element: <AgencyInventory /> },
            ],
        },
        {
            path: '/checkYourEmail',
            element: <CheckYourEmail />,
        },
        {
            path: '/forgotPassword',
            element: <ForgotPassword />,
        },
        {
            path: '/setNewPassword',
            element: <SetNewPassword />,
        },
        {
            path: '/invalidLink',
            element: <InvalidLink />,
        },
        {
            path: '/passwordReset',
            element: <PasswordReset />,
        },
        {
            path: '/',
            children: [
                { path: '/', element: <Navigate to="/dashboard" /> },
                { path: '/403', element: <ErrorPage /> },
                { path: '/404', element: <ErrorPage /> },
                { path: '/500', element: <ErrorPage /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" />,
        },
    ]);
    };

export default Router;
